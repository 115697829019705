import React, { useEffect, useState } from 'react';
import { useTable, useSortBy, usePagination, useFilters, Column } from 'react-table';
import axios from "axios";
import './deviceTable.css';
import upImage from '../../images/up.png';  // Import the image
import networksicon from '../../images/down.png';
import addDevice from '../../images/network.svg';
import { device } from '../../interface/device';
import EditDevice from './editDevice';
//import devicelist from '../../json/devicelist.json';
import moment from 'moment';
import CreateDevice from './createDevice';
import Pagination from '../utility/pagination';
import {Circles} from 'react-loader-spinner';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faCheckCircle, faMinusCircle,faUserLock,faMicrochip, faArrowsRotate, faLaptop, faUser} from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {useSelector} from 'react-redux';
import { Modal, Tooltip } from "react-bootstrap";
import {timestampToDaysHrsMin} from '../utility/helperFunction';
const columns: Column<device>[] = [

  {
    Header: 'Device Name',
    disableSortBy:false,
    accessor: (row: device) => {
      return (
        <div>
          {row.activity_status === "OFFLINE" ?  <FontAwesomeIcon style={{ color:'grey'}} icon={faCheckCircle as IconProp} /> :  <FontAwesomeIcon style={{ color:'green'}} icon={faCheckCircle as IconProp} />}
         <span style={{marginLeft:'5px'}}>{row.name}</span>
        </div>
      )
    },
  
  },
  {
    Header: 'Network',
    accessor: (row: device) => row.networks || '',
    disableSortBy:false,
  },
  {
    Header: 'Organization',
    accessor: (row: device) => row.tenant?.name || '',
    disableSortBy:true,
  },
  {
    Header: 'Mac Address',
  //  accessor: 'mac_address',
   accessor: (row: device) => row?.mac_addresses[0]?.mac_address || '',
    disableSortBy:true,
  },
  {
    Header: 'Device Owner',
    accessor: (row: device) => row.user?.display_name || '',
    disableSortBy:true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    disableSortBy:false,
  },
  {
    Header: 'UpTime',
    disableSortBy:true,
    accessor: (row: device) => {
      return (
        <div style={{justifyContent:"center", display:"flex"}}>
           <span style={{textAlign:'center'}}>{row.uptime}</span>
        </div>
      )
    }
  },
  {
    Header: 'Last Activity On',
    accessor: 'last_activity_on',
    disableSortBy:true,
  }
  // {
  //   Header: 'Last Updated On',
  //   accessor: 'modified_on',
  // }
];

/* interface DataResponse {
   total_records: number;
   items_per_page: number;
   networks: network[];
 }*/

interface DeviceTableProps {
  data: any;
}

const DeviceTable: React.FC<DeviceTableProps> = ({ data }) => {
  const userData = useSelector((state:any) => state.userReducer.userData);
  let json: string;
  let isSuperAdmin: boolean;
  let tenant_id: string;
  const [tableData, setTableData] = useState<any>([]);
  const [index, setIndex] = useState(1)
  const [totalRecords, setTotalRecords] = useState(0);
  const [recordPerPage, setrecordPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [deviceFlag,setDeviceFlag] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [searchStr, setSearchStr] = useState('');
  const [showModal,setShowModal] = useState(false);
  const [showReprovisionConfirmModal, setShowReprovisionConfirmModal] = useState(false);
  const [reprovisionDevice, setReprovisionDevice] = useState<any>(null)
  const [sortField, setSortField] = useState('created_on');
  const [sortOrder, setSortOrder] = useState('DESC');

  const displayModal = () => {
    setShowModal(true);
  }
  const closeModal = () => {
    setShowModal(false);
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setFilter,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable<any>(
    {
      columns,
      data: tableData,
      // initialState: { pageIndex: index, pageSize: recordPerPage }
    },
    useFilters,
    useSortBy,
    usePagination
  );

  // const { pageIndex, pageSize } = state;

  // const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState<device>({
    id: '',
    created_by: {
      id: '',
      email: '',
      first_name: '',
      last_name: '',
      display_name: ''
    },
    modified_by: {
      id: '',
      email: '',
      first_name: '',
      last_name: '',
      display_name: ''
    },
    is_online: false,
    user: {
      id: '',
      email: '',
      first_name: '',
      last_name: '',
      display_name: '',
      access_token: '',
      expires_at: 0,
      id_token: '',
      profile: {
        aud: '',
        email: '',
        email_verified: false,
        exp: 0,
        family_name: '',
        given_name: '',
        name: ''
      },
      refresh_token: '',
      session_state: '',
      token_type: ''
    },
    networks: {
      id: '',
      name: '',
      tenant: {
        id: '',
        device_count: '',
        network_count: '',
        name: '',
        description: '',
        country: '',
        state: '',
        address_line_1: '',
        address_line_2: '',
        addon_data: '',
        config: '',
        is_enabled: false,
        created_on: '',
        modified_on: '',
        user: {
          id: '',
          email: '',
          first_name: '',
          last_name: '',
          display_name: ''
        },
        created_by: {
          id: '',
          email: '',
          first_name: '',
          last_name: '',
          display_name: ''
        },
        modified_by: {
          id: '',
          email: '',
          first_name: '',
          last_name: '',
          display_name: ''
        },
        city: '',
        zipcode: '',
        industry: {
          id: '',
          name: '',
          config: '',
          is_enabled: false,
          created_on: '',
          modified_on: '',
          created_by: '',
          modified_by: ''
        },
        stats: {
          device: {
            total: '',
          },
          network: {
            total: '',
          }
        }
      },
      created_by: {
        id: '',
        email: '',
        first_name: '',
        last_name: '',
        display_name: ''
      },
      modified_by: {
        id: '',
        email: '',
        first_name: '',
        last_name: '',
        display_name: ''
      },
      device_count: 0,
      serial_no: 0,
      is_enabled: false,
      is_deleted: false,
      created_on: '',
      modified_on: '',
      stats: {
        device: {
          total: '',
        },
        network: {
          total: '',
        }
      }
    },
    serial_no: '',
    tenant: {
      id: '',
      device_count: '',
      network_count: '',
      name: '',
      description: '',
      country: '',
      state: '',
      address_line_1: '',
      address_line_2: '',
      addon_data: '',
      config: '',
      is_enabled: false,
      created_on: '',
      modified_on: '',
      user: {
        id: '',
        email: '',
        first_name: '',
        last_name: '',
        display_name: ''
      },
      created_by: {
        id: '',
        email: '',
        first_name: '',
        last_name: '',
        display_name: ''
      },
      modified_by: {
        id: '',
        email: '',
        first_name: '',
        last_name: '',
        display_name: ''
      },
      city: '',
      zipcode: '',
      industry: {
        id: '',
        name: '',
        config: '',
        is_enabled: false,
        created_on: '',
        modified_on: '',
        created_by: '',
        modified_by: ''
      },
      stats: {
        device: {
          total: '',
        },
        network: {
          total: '',
        }
      }

    },
    onboarding_ref: '',
    name: '',
    description: '',
    user_id: '',
   //mac_address: '',
   mac_addresses:'',
    status: '',
    uptime: '',
    last_activity_on: '',
    activity_status: '',
    is_enabled: false,
    is_deleted: false,
    created_on: '',
    modified_on: ''

  });
  const [userId, setUserId] = useState<string>('');
  const BASE_URL = process.env.REACT_APP_SERVICE_ENDPOINT;
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [deviceDetails,setDeviceDetails] = useState<device>({
    id: '',
    created_by: {
      id: '',
      email: '',
      first_name: '',
      last_name: '',
      display_name: ''
    },
    modified_by: {
      id: '',
      email: '',
      first_name: '',
      last_name: '',
      display_name: ''
    },
    is_online: false,
    user: {
      id: '',
      email: '',
      first_name: '',
      last_name: '',
      display_name: '',
      access_token: '',
      expires_at: 0,
      id_token: '',
      profile: {
        aud: '',
        email: '',
        email_verified: false,
        exp: 0,
        family_name: '',
        given_name: '',
        name: ''
      },
      refresh_token: '',
      session_state: '',
      token_type: ''
    },
    networks: {
      id: '',
      name: '',
      tenant: {
        id: '',
        device_count: '',
        network_count: '',
        name: '',
        description: '',
        country: '',
        state: '',
        address_line_1: '',
        address_line_2: '',
        addon_data: '',
        config: '',
        is_enabled: false,
        created_on: '',
        modified_on: '',
        user: {
          id: '',
          email: '',
          first_name: '',
          last_name: '',
          display_name: ''
        },
        created_by: {
          id: '',
          email: '',
          first_name: '',
          last_name: '',
          display_name: ''
        },
        modified_by: {
          id: '',
          email: '',
          first_name: '',
          last_name: '',
          display_name: ''
        },
        city: '',
        zipcode: '',
        industry: {
          id: '',
          name: '',
          config: '',
          is_enabled: false,
          created_on: '',
          modified_on: '',
          created_by: '',
          modified_by: ''
        },
        stats: {
          device: {
            total: '',
          },
          network: {
            total: '',
          }
        }
      },
      created_by: {
        id: '',
        email: '',
        first_name: '',
        last_name: '',
        display_name: ''
      },
      modified_by: {
        id: '',
        email: '',
        first_name: '',
        last_name: '',
        display_name: ''
      },
      device_count: 0,
      serial_no: 0,
      is_enabled: false,
      is_deleted: false,
      created_on: '',
      modified_on: '',
      stats: {
        device: {
          total: '',
        },
        network: {
          total: '',
        }
      }
    },
    serial_no: '',
    tenant: {
      id: '',
      device_count: '',
      network_count: '',
      name: '',
      description: '',
      country: '',
      state: '',
      address_line_1: '',
      address_line_2: '',
      addon_data: '',
      config: '',
      is_enabled: false,
      created_on: '',
      modified_on: '',
      user: {
        id: '',
        email: '',
        first_name: '',
        last_name: '',
        display_name: ''
      },
      created_by: {
        id: '',
        email: '',
        first_name: '',
        last_name: '',
        display_name: ''
      },
      modified_by: {
        id: '',
        email: '',
        first_name: '',
        last_name: '',
        display_name: ''
      },
      city: '',
      zipcode: '',
      industry: {
        id: '',
        name: '',
        config: '',
        is_enabled: false,
        created_on: '',
        modified_on: '',
        created_by: '',
        modified_by: ''
      },
      stats: {
        device: {
          total: '',
        },
        network: {
          total: '',
        }
      }

    },
    onboarding_ref: '',
    name: '',
    description: '',
    user_id: '',
   // mac_address: '',
   mac_addresses:'',
    status: '',
    uptime: '',
    last_activity_on: '',
    activity_status: '',
    is_enabled: false,
    is_deleted: false,
    created_on: '',
    modified_on: ''

  });
  /*
  fetch Data for devices*/

  const fetchData = async (searchString: string = '', sortColum: string = '', sortType: string = '') => {
    
    setLoading(true)
    const token = data?.access_token;
    const authToken = `Bearer ${token}`;
    const headers = {
      Authorization: authToken,
    };
    try {
      // const config_url: string = `${BASE_URL}/auth-service/users/introspect/`;
      // const responseData = await axios.get(config_url, { headers });
      // const tenant_id = (userData && userData[0].user.is_superadmin) ? '*' : userData[0].user.tenants.length > 0 ? userData[0].user.tenants[0].id : null ;
      const tenant_id = (userData && userData[0].user.roles[0].role.name === 'SYSTEM_ADMIN') ? '*' : userData[0].user.tenants.length > 0 ? userData[0].user.tenants[0].id : null ;
      // if (undefined !== responseData && null !== responseData.data)
      //   isSuperAdmin = responseData.data.user.is_superadmin;
      // if (isSuperAdmin) {
        try {
          
          const deviceURL: string = `${BASE_URL}/asset-service/tenants/${tenant_id}/devices/?k=${searchString}&page=${currentPage}&limit=${recordPerPage}&sort_field=${sortColum}&sort_order=${sortType}`;
          const response = await axios.get(deviceURL, {
            headers
          });
          const deviceList = response.data.devices;
          
          setTotalRecords(response.data.total_records)
          const formattedDevices = deviceList.map((device: any) => ({
            id: device.id,
            created_by: device.created_by,
            modified_by: device.modified_by,
            is_online: device.is_online,
            user: device.user,
            networks: device.networks.length > 0 ? device.networks[0].name : device.networks,
          //  networks: device.networks.length > 0 ? device.networks[0] : device.networks,
            serial_no: device.serial_no,
            tenant: device.tenant,
            onboarding_ref: device.onboarding_ref,
            name: device.name,
            description: device.description,
            user_id: device.user_id,
           // mac_address: device.mac_address,
           mac_addresses:device.mac_addresses,
            status: device.status,
            uptime: timestampToDaysHrsMin(device.uptime),//device.uptime !== null && moment.unix(Math.ceil(device.uptime)*1000).format('HH:mm:ss'),
            last_activity_on: null !== device.last_activity_on ? moment.unix(parseInt(device.last_activity_on)).format('YYYY-MM-DD HH:mm:ss') : null,
            activity_status: device.activity_status,
            is_enabled: device.is_enabled,
            is_deleted: device.is_deleted,
            created_on: device.created_on,
            modified_on: moment.unix(parseInt(device.modified_on)).format('YYYY-MM-DD HH:mm:ss')
          }));

         
          setTableData(formattedDevices);
          setLoading(false)
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(false)
        }
      // }
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false)
    }
  }
  
  const fetchDeviceDetails = async (deviceInfo:device) => {

    try {
    const token = data?.access_token;
    const authToken = `Bearer ${token}`;
    const headers = {
      Authorization: authToken,
    };
    const deviceDetailsURL:string = `${BASE_URL}/asset-service/tenants/${deviceInfo.tenant.id}/devices/${deviceInfo.id}`;
    const response = await axios.get(deviceDetailsURL, { headers });
  
    setDeviceDetails(response.data.device);
    displayModal();

  } catch (error: any) {
    return { status: false, message: error.response.data.error.message, data: {} }
  }
    
  }

  useEffect(() => {
    fetchData(searchStr, sortField,sortOrder);
  }, [currentPage,recordPerPage,userData]);

  const handleSave = (message:any) => {
    
    fetchData(searchStr, sortField,sortOrder);
    setSuccessMessage(message.message);
    setTimeout(()=>{
      setSuccessMessage(null);
    },10000)
  };

  const handleEdit = (message:any) => {
    // fetchData(searchStr);
    fetchData(searchStr, sortField,sortOrder);
    setSuccessMessage(message.message);
    setTimeout(()=>{
      setSuccessMessage(null);
    },10000)
  };

  const handleDelete = (message: any) => {
    // setTableData((prevData: any[]) => prevData.filter((device) => device.id !== deviceId));
    fetchData(searchStr, sortField,sortOrder);
    setSuccessMessage(message.message);
    setTimeout(()=>{
      setSuccessMessage(null);
    },10000)
  }
  const handelApprovalStatusChange = async (status:string, row:any) => {
    const token = data?.access_token;
    const authToken = `Bearer ${token}`;
    const headers = {
      Authorization: authToken,
    };
    let approvalStatus = {
      approval_status: status
    }
    let tenant_id = row.tenant.id;
    let onboarding_ref = row.onboarding_ref;
    let device_id = row.id;
    try {
      const deviceURL: string = `${BASE_URL}/asset-service/tenants/${tenant_id}/devices/${device_id}/onboarding-approval/${onboarding_ref}/`;
      const response = await axios.patch(deviceURL,approvalStatus ,{
        headers
      });
      fetchData(searchStr, sortField,sortOrder);
    }catch(error:any){
      console.log(error)
    }
  }
  const handelReprovisionStatusChange = async (status:string, row:any) => {
    const token = data?.access_token;
    const authToken = `Bearer ${token}`;
    const headers = {
      Authorization: authToken,
    };
    let approvalStatus = {
      approval_status: status
    }
    let tenant_id = row.tenant.id;
    let onboarding_ref = row.onboarding_ref;
    let device_id = row.id;
    try {
      const deviceURL: string = `${BASE_URL}/asset-service/tenants/${tenant_id}/devices/${device_id}/reprovisioning/`;
      
      // return false;
      const response = await axios.patch(deviceURL,approvalStatus ,{
        headers
      });
      fetchData(searchStr, sortField,sortOrder);
    }catch(error:any){
      console.log(error)
    }
  }
  const handleSorting = (data:any, sort:string) => {
    let sortFieldString = ''
    if(data.id === 'Device Name'){
      sortFieldString = 'name';
    }else if(data.id === 'Network'){
      sortFieldString= 'network';
    }else if(data.id === 'Status'){
      sortFieldString= 'status';
    }else{
      sortFieldString= data.id;
    }
    
    if(sortFieldString !== '' && !data.disableSortBy){
      setSortField(sortFieldString);
    setSortOrder(sort);
      fetchData(searchStr,sortFieldString,sort);
    }
    
  }

  return (

    /*Device Table */
    <div className="bg-box p-3">
      <div className="App ">
        <h3 className='titelcolostyle'>
        <FontAwesomeIcon icon={faLaptop as IconProp} />
          <span className='deviceHeader'>Devices</span>
          </h3>
          <div>
          <div>
          {/* <Tooltip title="refresh data">  */}
            <button
              className='refreshBtn'
              onClick={()=>fetchData(searchStr,'created_on', 'DESC')}
              title="Refresh data"
              // value="Refresh"
            >
            {/* */}
            Refresh <FontAwesomeIcon icon={faArrowsRotate as IconProp}  />
              {/*  */}
            </button>
            {/* </Tooltip> */}
          </div>
          <div className='header-fuctions'>
       
          <div><input
            type="text"
            placeholder="Search Name"
            onChange={(e) => {(e.target.value.length >= 3 || e.target.value.length === 0) && fetchData(e.target.value); setIsFilter((e.target.value.length > 0) ? true : false); setSearchStr(e.target.value)}}
            className="form-control mb-3 cutomesearchbox"
          />

          </div>
          <div>
            <button
              data-bs-toggle="modal"
              data-bs-target="#exampleModalDevice"
              className='addbtn'
            >
              Add device
            </button>
          </div>

        </div>
            
          </div>
        
        {
              null !== successMessage &&
              <div>
                <p style={{ color: 'blue' }}>Success: {successMessage}</p>
              </div>
            }
        <div className='scrollable-table-test'>
        {loading ? 
      <div style={{marginTop:"100"}}>
      <Circles color="#7C7AFD" height={50} width={50} wrapperStyle={{justifyContent:"center",marginTop:"150px"}}  />
      </div>
      :
          <table {...getTableProps()} className="table ">
            <thead className='sticky-top'>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    
                    <th onClick={()=>handleSorting(column, sortOrder === 'DESC'?'ASC':'DESC')}>
                      {column.render('Header')}
                      {
                        !column.disableSortBy && 
                        <span>
                        {
                          
                          sortOrder === 'DESC' ? (
                            <img src={upImage}  className='icon-left-m' alt="Down Arrow" onClick={()=>handleSorting(column,'ASC')} />
  
                          ) : (
                            <img src={networksicon} className='icon-left-m' alt="Up Arrow" onClick={()=>handleSorting(column,'DESC')} />
                          )
                        }
                        
                        {/* {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''} */}
                        
                      </span>
                      }
                      
                    </th>
                  ))}
                  <th>Action</th>
                  <th></th>
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                     {row.cells.map((cell) => (
                     <td 
                      {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                    <td>
                      <div className='list-action'>
                        <div>
                          {
                            (row.original.status === 'ROGUE' || row.original.status === 'ONBOARDED' || row.original.status === 'CLONED' || row.original.status === 'ONBOARDING_APPROVED') ? 
                            <button data-toggle="tooltip" data-placement="top" title="Reprovision" style={{background: "linear-gradient(90deg, rgba(54,50,218,1) 0%, rgba(182,113,253,1) 100%)"}}
                             onClick={()=>{setShowReprovisionConfirmModal(true); setReprovisionDevice(row.original);}} type="button" className="btn btn-sm btn-success mr-2"><FontAwesomeIcon icon={faUserLock as IconProp} /></button> 
                            :
                            <>
                        <div className="dropdown">
                          <button disabled={row.original.status === 'PENDING_ONBOARDING_APPROVAL'? false: true} className="btn btn-secondary dropdown-toggle btn-sm mr-2" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                          <FontAwesomeIcon icon={faUser as IconProp} />
                          </button>
                          <ul className="dropdown-menu actionDropdown">
                            <li><span className="dropdown-item" onClick={()=>handelApprovalStatusChange('APPROVED',row.original)}>Approve</span></li>
                            <li><span className="dropdown-item" onClick={()=>handelApprovalStatusChange('REJECT',row.original)}>Reject</span></li>
                          
                          </ul>
                        </div>
                          {/* <button onClick={()=>handelApprovalStatusChange('APPROVED',row.original)} type="button" className="btn btn-sm btn-success" data-toggle="tooltip" data-placement="top" title="Approve"><FontAwesomeIcon icon={faCheckCircle as IconProp} />
                          
                          </button>
                          <button onClick={()=>handelApprovalStatusChange('REJECT',row.original)} type="button" className="btn btn-sm btn-danger" data-toggle="tooltip" data-placement="top" title="Reject" ><FontAwesomeIcon icon={faMinusCircle as IconProp} /></button>  */}
                        </>

                          }
                        {
                        // row.original.status === 'PENDING_ONBOARDING_APPROVAL' ? 
                        // <>
                        // <div className="dropdown">
                        //   <button disabled={row.original.status === 'PENDING_ONBOARDING_APPROVAL'? false: true} className="btn btn-secondary dropdown-toggle btn-sm mr-2" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        //   <FontAwesomeIcon icon={faUser as IconProp} />
                        //   </button>
                        //   <ul className="dropdown-menu actionDropdown">
                        //     <li><span className="dropdown-item" onClick={()=>handelApprovalStatusChange('APPROVED',row.original)}>Approve</span></li>
                        //     <li><span className="dropdown-item" onClick={()=>handelApprovalStatusChange('REJECT',row.original)}>Reject</span></li>
                          
                        //   </ul>
                        // </div>
                         
                        // </>
                        // : (row.original.status === 'ROGUE' || row.original.status === 'ONBOARDED' || row.original.status === 'CLONED' || row.original.status === 'ONBOARDING_APPROVED') ? 
                        // <button data-toggle="tooltip" data-placement="top" title="Reprovision" style={{background: "linear-gradient(90deg, rgba(54,50,218,1) 0%, rgba(182,113,253,1) 100%)"}}
                        //  onClick={()=>{setShowReprovisionConfirmModal(true); setReprovisionDevice(row.original);}} type="button" className="btn btn-sm btn-success mr-2"><FontAwesomeIcon icon={faUserLock as IconProp} /></button> 
                        // : null
                      }
                          {/* <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" /> */}
                          
                        </div>

                        {/* <button
                          className="btn btn-secondary btn-sm mr-2" data-bs-toggle="modal"
                          data-bs-target="#exampleModalDeviceEdit"
                          onClick={() => { setSelectedDevice(row.original); 
                            fetchDeviceDetails(row.original) }}
                        
                        >
                          View
                        </button> */}
                      </div>

                      {/* <button
                onClick={() => handleDelete(row.original.id)}
                className="btn btn-danger"
              >
                Delete
              </button> */}
                    </td>
                    <td>
                    <button
                          className="btn btn-secondary btn-sm mr-2" onClick={() => {
                            setSelectedDevice(row.original); 
                            fetchDeviceDetails(row.original);
                            setDeviceFlag(true);
                            }}>
                          View
                        </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
}
        </div>
        <div className="paginationOuterDiv">
          <div style={{ width: "35%" }}>
          <span>Select # of Rows  &nbsp;</span>
            <select
              value={recordPerPage}
              onChange={(e) => {
                setrecordPerPage(Number(e.target.value)); setCurrentPage(1);
              }}
              style={{ width: 50, marginTop: 10, textAlign:"center" }}
            >
              {[25, 50, 75, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div style={{ width: "65%" }}>
            <Pagination key={currentPage}
              totalRows={totalRecords}
              pageChangeHandler={setCurrentPage}
              rowsPerPage={recordPerPage}
              setFilter={setIsFilter}
              currentPageNumber={currentPage}
            />
          </div>
        </div>
        {/* <select
          value={recordPerPage}
          onChange={(e) => {
            setrecordPerPage(Number(e.target.value));setCurrentPage(1);
          }}
          style={{width:50}}
        >
          {[25, 50, 75, 100].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </select>
        <Pagination key={currentPage}
          totalRows={totalRecords}
          pageChangeHandler={setCurrentPage}
          rowsPerPage={recordPerPage}
          setFilter={setFilter}
          currentPageNumber={currentPage}
        /> */}

        <CreateDevice userData={data} onSave={handleSave} userId={userId} />
        {
          showModal && 
          <EditDevice userData={data} data={selectedDevice} onEdit={handleEdit} onDelete={handleDelete} deviceDetailsInfo={deviceDetails} isOpen={showModal} closeModal={closeModal} />
        }
        {
          showReprovisionConfirmModal && 
          <div>
            <Modal dialogClassName='delete-dialog' backdrop="static" show={showReprovisionConfirmModal} onHide={() => setShowReprovisionConfirmModal(false)} style={{marginTop:'5%'}}>
  
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                <h5 className="confirmModal"> Confirm Reprovision</h5></h5>
              {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
              <button type="button" className="btn-close"  aria-label="Close" onClick={() => {setShowReprovisionConfirmModal(false) }}></button>
            </div>
            <div className="modal-body">
              <p> Are you sure you want to reprovison the device <b>{reprovisionDevice && reprovisionDevice !== null && reprovisionDevice.name}</b> ?</p> 
            </div>
            <div className="modal-footer">
                {/* <button type="button" className="btn btn-danger"
                onClick={() => handleDelete(data)}  data-bs-dismiss="modal">Yes</button> */}
                 <button type="button" className="btn btn-danger"
                onClick={() => {handelReprovisionStatusChange('REPROVISION',reprovisionDevice);setShowReprovisionConfirmModal(false)}}  >Yes</button>
                {/* <button type="button" className="btn btn-primary"
                data-bs-dismiss="modal">No</button> */}
                 <button type="button" className="btn btn-light" onClick={() => {setShowReprovisionConfirmModal(false) }}>No</button>
            </div>
           
        
        </Modal>
      </div>

        }
        
       
      
       
      </div>
    </div>
  );
}

export default DeviceTable;

